<template>
    <b-container>
        <div
            class="d-flex align-items-center justify-content-center mt-5"
            data-aos="fade-in"
            data-aos-duration="3000"
        >
            <h3 style="color: white; font-weight: bold;">
                {{ t("contactus").contactus }}
            </h3>
            <img src="media/svg/contactus.svg" class="mx-2" />
        </div>
        <p
            class="text-center mx-auto my-2"
            style="max-width:475px; color: white; font-weight: normal;"
        >
            هذا النص هو مثال لنص يمكن أن يسبى، إضافة إلى زيادة عدد الحروف التى
            يولدها التطبيق.
        </p>
        <div class="scope">
            <ValidationObserver ref="observer">
                <div class="div-form mt-5">
                    <b-row>
                        <b-col
                            cols="12"
                            lg="6"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                        >
                            <EKInputText
                                class="d-block sign-up"
                                v-model="contactDto.name"
                                :rules="[
                                    {
                                        type: 'required',
                                        message:
                                            t('contactus').write +
                                            ' ' +
                                            t('contactus').name
                                    }
                                ]"
                                :placeholder="
                                    t('contactus').write +
                                        ' ' +
                                        t('contactus').name
                                "
                                name="name"
                            />
                        </b-col>
                        <b-col
                            cols="12"
                            lg="6"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                        >
                            <EKInputText
                                class="d-block sign-up"
                                v-model="contactDto.mobileNumber"
                                :rules="[
                                    {
                                        type: 'required',
                                        message:
                                            t('contactus').write +
                                            ' ' +
                                            t('contactus').mobile
                                    },
                                    {
                                        type: 'mobile',
                                        message: t('contactus').validMobile
                                    }
                                ]"
                                :placeholder="
                                    t('contactus').write +
                                        ' ' +
                                        t('contactus').mobile
                                "
                                name="mobile"
                            />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            lg="6"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                        >
                            <EKInputText
                                class="d-block sign-up"
                                v-model="contactDto.subject"
                                :rules="[
                                    {
                                        type: 'required',
                                        message:
                                            t('contactus').write +
                                            ' ' +
                                            t('contactus').subject
                                    }
                                ]"
                                :placeholder="
                                    t('contactus').write +
                                        ' ' +
                                        t('contactus').subject
                                "
                                name="title"
                            />
                        </b-col>
                        <b-col
                            cols="12"
                            lg="6"
                            data-aos="fade-up"
                            data-aos-duration="2500"
                        >
                            <EKInputText
                                class="d-block sign-up"
                                v-model="contactDto.email"
                                :rules="[
                                    {
                                        type: 'required',
                                        message:
                                            t('contactus').write +
                                            ' ' +
                                            t('contactus').email
                                    },
                                    {
                                        type: 'email',
                                        message: t('contactus').emailval
                                    }
                                ]"
                                :placeholder="
                                    t('contactus').write +
                                        ' ' +
                                        t('contactus').email
                                "
                                name="email"
                            />
                        </b-col>
                    </b-row>
                    <div data-aos="fade-up" data-aos-duration="3000">
                        <EKInputTextarea
                            :rules="[
                                {
                                    type: 'required',
                                    message: t('contactus').message
                                }
                            ]"
                            v-model="contactDto.description"
                            name="description"
                            rows="4"
                            class="w-100 d-block mt-1 sign-up"
                            :placeholder="t('contactus').message + '...'"
                        />
                    </div>
                    <div class="d-flex justify-content-center my-4">
                        <b-button
                            variant="flat-secondary"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                            class="text-nowrap d-flex align-items-center d-block mx-auto"
                            style="justify-self: center;background: none; border: none; position: relative;"
                            @click="submitForm"
                        >
                            <svg
                                width="159"
                                height="69"
                                viewBox="0 0 159 69"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g filter="url(#filter0_d)">
                                    <path
                                        d="M17.1788 18.1497C17.6657 15.9859 19.5198 14.4014 21.7331 14.2578L143.676 6.34543C146.559 6.15839 149 8.44613 149 11.3349V49.5C149 52.2614 146.761 54.5 144 54.5H15.25C12.0458 54.5 9.6686 51.5285 10.372 48.4024L17.1788 18.1497Z"
                                        fill="#196DA7"
                                    />
                                </g>
                                <defs>
                                    <filter
                                        id="filter0_d"
                                        x="0.246094"
                                        y="0.334717"
                                        width="158.752"
                                        height="68.1653"
                                        filterUnits="userSpaceOnUse"
                                        color-interpolation-filters="sRGB"
                                    >
                                        <feFlood
                                            flood-opacity="0"
                                            result="BackgroundImageFix"
                                        />
                                        <feColorMatrix
                                            in="SourceAlpha"
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"
                                        />
                                        <feOffset dy="4" />
                                        <feGaussianBlur stdDeviation="5" />
                                        <feComposite
                                            in2="hardAlpha"
                                            operator="out"
                                        />
                                        <feColorMatrix
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                                        />
                                        <feBlend
                                            mode="normal"
                                            in2="BackgroundImageFix"
                                            result="effect1_dropShadow"
                                        />
                                        <feBlend
                                            mode="normal"
                                            in="SourceGraphic"
                                            in2="effect1_dropShadow"
                                            result="shape"
                                        />
                                    </filter>
                                </defs>
                            </svg>
                            <span
                                style="position: absolute; right: 5.5rem; color: #FFFFFF;"
                            >
                                {{ t("contactus").send }}
                            </span>
                        </b-button>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { ValidationObserver } from "vee-validate";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputTextarea from "@Ekcore/components/EK-forms/EK-input-textarea";
export default {
    components: {
        ValidationObserver,
        EKInputText,
        EKInputTextarea
    },
    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    metaInfo() {
        return {
            titleTemplate: "Awija - " + this.$t("website.contactus"),
            htmlAttrs: {
                lang: this.$i18n.locale
            },
            meta: [
                {
                    name: "description",
                    content: "description"
                },
                {
                    name: "keywords",
                    content: "keywords, keywords, keywords, ..."
                }
            ]
        };
    },
    computed: {
        ...mapState({
            contactDto: state => state.global.contactDto
        })
    },
    methods: {
        ...mapActions(["setContact"]),
        submitForm() {
            this.$refs.observer.validate().then(suc => {
                if (suc) {
                    this.setContact(this.contactDto);
                }
            });
        },
        enterSubmit({ key, code }) {
            if (key == "Enter" || code == "Enter") {
                this.submitForm();
            }
        }
    },
    mounted() {
        window.addEventListener("keypress", this.enterSubmit);
    }
};
</script>

<style scoped lang="scss">
.scope ::v-deep .sign-up .form-control {
    padding: 2.5rem !important;
    background-color: inherit;
    border: solid 3px white;
    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
        inset 2px -2px 4px rgba(0, 0, 0, 0.1);
    color: #ffffff;
}
.scope ::v-deep .custom-control-label:after {
    border: white solid 2px;
    border-radius: 1.5px;
    background-color: var(--custom-dark);
}

.scope ::v-deep .custom-control-label {
    color: #ffffff;
}
</style>
